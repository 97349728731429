import IRR from "./app";

function displayOutput(roundedReturn) {
    document.getElementById("mb_return_output").hidden = false;
    const displayText = `Annualized Rate of return is: ${roundedReturn}% per year`;
    document.getElementById("mb_annual_return").innerText = displayText;
}
function calculateMoneyback(){
    const totalTerm = document.getElementById("policy_term").value;
    const payingTerm = document.getElementById("paying_term").value;
    const annualPremium = document.getElementById("annual_premium").value;
    const totalAmount = document.getElementById("total_amount").value;
    const values = Array(payingTerm).fill(-annualPremium);
    values.concat(Array(totalTerm-payingTerm).fill(0));
    values.push(totalAmount);
    const annualReturn = IRR(values);
    const roundedReturn = Math.round(annualReturn * 100)/100;
    displayOutput(roundedReturn);
}


document.getElementById ("calculatemb").addEventListener ("click", calculateMoneyback, false);
